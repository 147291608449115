import { useContext, useEffect, useState } from "react";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import { getShop } from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes";
import Select from "react-select";
import { AdminContext } from "../../../Context/admin";
export default function AdminPartners() {
  const arr = [
    {
      value: "Name",
      label: "Название",
    },
    {
      value: "City",
      label: "Адрес",
    },
    {
      value: "category",
      label: "Рубрика",
    },
  ];
  const [currentSearch, setCurrentSearch] = useState(arr[0]);
  const [search, setSearch] = useState("");
  const [partners, setPartners] = useState();
  const Navigate = useNavigate();
  const [admin, setAdmin] = useContext(AdminContext);
  useEffect(() => {
    getShop().then((data) => {
      setPartners(data);
    });
  }, []);

  return (
    <div className="AdminPartners cont">
      <AdminMenu></AdminMenu>
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="adminPartnersBody">
          <div className="buttonsAndOther">
            <h4>Партнеры</h4>
            <Select
              value={currentSearch}
              onChange={setCurrentSearch}
              name="Name"
              options={arr}
            />
            <input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Поиск.."
            />
            <div
              className="addPartner"
              onClick={() => Navigate(routes.adminAddPartner)}
            >
              Добавить партнера
            </div>
            <div className="downloadOth">
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava"></div>
              <div className="pName">Название</div>
              <div className="pRub">Рубрика</div>
              <div className="pUser">% польз.</div>
              <div className="pTel">Телефон</div>
              <div className="pWeb">Вебсайт</div>
              <div className="pAdress">Адрес</div>
              <div className="pGr">График работы</div>
            </div>
            {partners && partners.length > 0 && admin.isAdmin == "1"
              ? partners.map((em, index) => {
                  if (
                    em[currentSearch.value]
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(
                            routes.adminPartnerData +
                              "?key=" +
                              JSON.stringify(em)
                          )
                        }
                      >
                        <div className="ava">
                          <img
                            src={em.Image.length > 0 ? em.Image : ""}
                            alt=""
                          />
                        </div>
                        <div className="pName">{em.Name}</div>
                        <div className="pRub">{em.category}</div>
                        <div className="pUser">{em.SaleForClients}</div>
                        <div className="pTel">{em.Tel}</div>
                        <div className="pWeb">{em.WebSyte}</div>
                        <div className="pAdress">{em.City}</div>
                        <div className="pGr">{em.WorkSchedule}</div>
                      </div>
                    );
                  }
                })
              : partners && partners.length > 0 && admin.isAdmin == "0"
              ? partners.map((em, index) => {
                  if (
                    em[currentSearch.value]
                      .toLowerCase()
                      .includes(search.toLowerCase()) &&
                    admin.City == em.City
                  ) {
                    return (
                      <div
                        className="admPcol rzd"
                        key={"partner" + index}
                        onClick={() =>
                          Navigate(
                            routes.adminPartnerData +
                              "?key=" +
                              JSON.stringify(em)
                          )
                        }
                      >
                        <div className="ava">
                          <img
                            src={em.Image.length > 0 ? em.Image : ""}
                            alt=""
                          />
                        </div>
                        <div className="pName">{em.Name}</div>
                        <div className="pRub">{em.category}</div>
                        <div className="pUser">{em.SaleForClients}</div>
                        <div className="pTel">{em.Tel}</div>
                        <div className="pWeb">{em.WebSyte}</div>
                        <div className="pAdress">{em.City}</div>
                        <div className="pGr">{em.WorkSchedule}</div>
                      </div>
                    );
                  }
                })
              : ""}
          </div>
        </div>
      </div>
    </div>
  );
}
