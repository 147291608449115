import "./style.css";
import logo from "./img/logo.png";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import { useContext, useEffect, useState } from "react";
import { getCategories, getShop } from "../../Api/Api";
import { CityContext } from "../../Context/city";
export default function Partners() {
  const Navigate = useNavigate();
  const [partners, setPartners] = useState();
  const [city, setCity] = useContext(CityContext);
  const [category, setCatgory] = useState();
  useEffect(() => {
    getShop().then((data) => {
      setPartners(data);
    });
    getCategories().then((data) => {
      setCatgory(data);
    });
  }, []);
  return (
    <div className="partners">
      <div className="nav" onClick={() => window.history.back()}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 7L9 13L15 19"
            stroke="#474753"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p>Партнеры</p>
      </div>
      <div className="categoryPartners">
        {category &&
          category.length > 0 &&
          category.map((em) => {
            return <p>{em.category}</p>;
          })}
      </div>
      <input type="text" className="search" placeholder="Поиск..." />
      <div className="partnersList">
        {partners &&
          partners.length > 0 &&
          partners.map((em, index) => {
            if (city.value == em.City) {
              return (
                <div
                  key={"partners" + index}
                  className="partnerItem"
                  onClick={() => Navigate(routes.partnerPage + `?key=${em.ID}`)}
                >
                  <img src={em.Image} alt="" />
                  <p>{em.Name}</p>
                  <div className="sl">{em.SaleForClients}%</div>
                </div>
              );
            }
          })}
      </div>
    </div>
  );
}
