import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import "./style.css";
import { useState } from "react";
import { getAdress } from "../../Api/Api";
import Select from "react-select";
import { AddressSuggestions } from "react-dadata";
export default function Registration() {
  const [options, setOptions] = useState([]);
  const [faceNumber, setFaceNumber] = useState(
    localStorage.faceNumber ? localStorage.faceNumber : ""
  );
  const [adress, setAdress] = useState(
    localStorage.adress ? localStorage.adress : ""
  );
  console.log(adress);
  const Navigate = useNavigate();
  return (
    <div className="registration">
      <img className="logoReg" src="/img/logo.svg" alt="" />
      <h4 className="h4Main">Регистрация</h4>
      <input
        value={faceNumber}
        onChange={(e) => setFaceNumber(e.target.value)}
        type="text"
        placeholder="Номер лицевого счета"
        className="inputComp"
      />

      <AddressSuggestions
        token="307eb1b5e51c6846adac5e5c92e1b3a5de831912"
        value={adress}
        onChange={setAdress}
        placeholder="Введите ваш город"
        inputProps={{ placeholder: "Ваш город" }}
      />
      <div
        className="button"
        style={
          faceNumber.length < 5 || adress.length < 5
            ? { opacity: "0.5", pointerEvents: "none" }
            : {}
        }
        onClick={() => {
          localStorage.faceNumber = faceNumber;
          localStorage.adress = JSON.stringify(adress);
          Navigate(routes.completeRegister);
        }}
      >
        Далее
      </div>
      <div className="back" onClick={() => Navigate(routes.auth)}>
        СТРАНИЦА ВХОДА
      </div>
    </div>
  );
}
