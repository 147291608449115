import { useNavigate } from "react-router-dom";
import AdminHeader from "../../../Components/AdminHeader";
import AdminMenu from "../../../Components/AdminMenu";
import "./style.css";
import { routes } from "../../../routes";
import { useEffect, useState } from "react";
import { activateDillers, getdillers } from "../../../Api/Api";
import icon from "./icon.svg";
import Notice from "../../../Func/Func";
export default function AdminDillers() {
  const Navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getdillers().then((data) => {
      setUsers(data);
    });
  }, []);
  return (
    <div className="cont">
      <AdminMenu />
      <div className="mainCont">
        <AdminHeader></AdminHeader>
        <div className="usersCont">
          <div className="buttonsAndOther">
            <h4>Диллеры</h4>
            <input type="text" value={search} placeholder="Поиск.." />

            <div
              className="downloadOth"
              onClick={() =>
                window.open("https://domcash.ru/omg.php", "_blank")
              }
            >
              Скачать отчет
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.46967 12.0303C5.76256 12.3232 6.23744 12.3232 6.53033 12.0303L11.3033 7.25736C11.5962 6.96447 11.5962 6.48959 11.3033 6.1967C11.0104 5.90381 10.5355 5.90381 10.2426 6.1967L6 10.4393L1.75736 6.1967C1.46447 5.90381 0.989592 5.90381 0.696699 6.1967C0.403805 6.48959 0.403805 6.96447 0.696699 7.25736L5.46967 12.0303ZM5.25 0.5L5.25 11.5L6.75 11.5L6.75 0.5L5.25 0.5Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
          <div className="partnersTable">
            <div className="adminTableTitle rzd">
              <div className="ava">ID</div>
              <div className="pName">Логин</div>
              <div className="pRub">ИНН</div>
              <div className="pUser">Email</div>
              <div className="pTel">Юр.имя</div>
              <div className="pWeb">Город</div>
              <div className="pAdress">Активация</div>
              <div className="pGr">Пароль</div>
            </div>
            {users &&
              users.length > 0 &&
              users.map((em, index) => {
                return (
                  <div
                    className="admPcol rzd"
                    key={"partner" + index}
                    onClick={() =>
                      Navigate(routes.dillersPage + "?key=" + em.ID)
                    }
                  >
                    <div className="ava">{em.ID}</div>
                    <div className="pName">{em.Login}</div>
                    <div className="pRub">{em.Inn}</div>
                    <div className="pUser">{em.Email}</div>
                    <div className="pTel">{em.urName}</div>
                    <div className="pWeb">{em.City}</div>
                    <div className="active">
                      {em.Activate == 1 ? (
                        <img
                          src={icon}
                          alt=""
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            activateDillers(em.ID, 0).then((status) => {
                              if (status == 200) {
                                getdillers().then((data) => {
                                  setUsers(data);
                                });
                                Notice(
                                  "Диллеры",
                                  "Аккаунт деактивирован",
                                  "success"
                                );
                              }
                            });
                          }}
                        />
                      ) : (
                        <p
                          className="notAct"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            activateDillers(em.ID, 1).then((status) => {
                              if (status == 200) {
                                getdillers().then((data) => {
                                  setUsers(data);
                                  Notice(
                                    "Диллеры",
                                    "Аккаунт активирован",
                                    "success"
                                  );
                                });
                              }
                            });
                          }}
                        ></p>
                      )}{" "}
                    </div>
                    <div className="pGr">{em.Password}</div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}
