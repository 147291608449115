import { useContext, useState } from "react";
import "./style.css";
import tels from "./tel.png";
import { authAdmin, registerAdmin } from "../../../Api/Api";
import { routes } from "../../../routes";
import { useNavigate } from "react-router-dom";
import { Store } from "react-notifications-component";
import { AdminContext } from "../../../Context/admin";
import Notice from "../../../Func/Func";
export default function AuthAdmin() {
  const [reg, setReg] = useState(true);
  const [form, setForm] = useState({
    Login: "",
    Email: "",
    urName: "",
    Inn: "",
    City: "",
    Password: "",
  });
  const [admin, setAdmin] = useContext(AdminContext);
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };
  const Navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className="authAdmin">
      <div className="authAdmLeft">
        <p>Для входа в личный кабинет зарегистрируйтесь</p>
        <h3>
          <span
            style={
              !reg ? { color: "rgba(255,255,255,0.15)" } : { color: "#fff" }
            }
            onClick={() => setReg(true)}
          >
            Вход
          </span>{" "}
          <span
            style={
              reg ? { color: "rgba(255,255,255,0.15)" } : { color: "#fff" }
            }
            onClick={() => setReg(false)}
          >
            Регистрация
          </span>
        </h3>
        {reg ? <h4>в личный кабинет</h4> : <h4>в личном кабинете</h4>}

        {reg ? (
          <div className="regF">
            <div className="authAdmForm">
              <p>Авторизация</p>
              <input
                type="text"
                placeholder="Логин"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />

              <input
                type="password"
                placeholder="Пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span>Забыли пароль?</span>
              <div
                className="regs"
                onClick={() => {
                  authAdmin(login, password).then((data) => {
                    if (data != "error") {
                      if (data[0].Activate == "1") {
                        localStorage.adminData = JSON.stringify(data[0]);
                        setAdmin(data[0]);
                        Navigate(routes.adminPartners);
                      } else {
                        Store.addNotification({
                          title: "Данные на проверке",
                          message: `Аккаунт с такими данными находится на проверке`,
                          type: "warning",
                          insert: "top",
                          container: "top-right",
                          animationIn: [
                            "animate__animated",
                            "animate__bounceIn",
                          ],
                          animationOut: [
                            "animate__animated",
                            "animate__backOutUp",
                          ],
                          dismiss: {
                            duration: 3000,
                            onScreen: true,
                          },
                        });
                      }
                    } else {
                      Notice("Ошибка", "Неверные данные для входа", "danger");
                    }
                  });
                }}
              >
                Войти
              </div>
            </div>
          </div>
        ) : (
          <div className="authAdmForm">
            <p>Регистрация</p>
            <input
              type="text"
              value={form.Login}
              onChange={handleOnChange}
              placeholder="Логин"
              name="Login"
            />
            <input
              type="text"
              value={form.Email}
              onChange={handleOnChange}
              placeholder="Почта"
              name="Email"
            />
            <input
              type="text"
              placeholder="Юридическое наименование"
              name="urName"
              value={form.urName}
              onChange={handleOnChange}
            />
            <input
              type="text"
              value={form.Inn}
              onChange={handleOnChange}
              placeholder="ИНН"
              name="Inn"
            />
            <input
              type="text"
              value={form.City}
              onChange={handleOnChange}
              placeholder="Город"
              name="City"
            />
            <input
              type="password"
              value={form.Password}
              onChange={handleOnChange}
              placeholder="Пароль"
              name="Password"
            />
            <div
              className="regs"
              onClick={() => {
                console.log(form);
                if (
                  form.Login.length > 4 &&
                  form.Email.length > 4 &&
                  form.urName.length > 5 &&
                  form.Inn.length > 8 &&
                  form.City.length > 2 &&
                  form.Password.length >= 6
                ) {
                  registerAdmin(form).then((status) => {
                    if (status == 200) {
                      Store.addNotification({
                        title: "Регистрация",
                        message: `Регистрация прошла успешно, ваши данные находятся на проверке`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__bounceIn"],
                        animationOut: [
                          "animate__animated",
                          "animate__backOutUp",
                        ],
                        dismiss: {
                          duration: 3000,
                          onScreen: true,
                        },
                      });
                    } else if (status == 401) {
                      Store.addNotification({
                        title: "Ошибка",
                        message: `Аккаунт с такими данными уже зарегистрирован`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animate__animated", "animate__bounceIn"],
                        animationOut: [
                          "animate__animated",
                          "animate__backOutUp",
                        ],
                        dismiss: {
                          duration: 3000,
                          onScreen: true,
                        },
                      });
                    }
                  });
                } else {
                  Store.addNotification({
                    title: "Ошибка",
                    message: `Вы не заполнили все поля, или ваш пароль менее 6ти символов`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__bounceIn"],
                    animationOut: ["animate__animated", "animate__backOutUp"],
                    dismiss: {
                      duration: 3000,
                      onScreen: true,
                    },
                  });
                }
              }}
            >
              Зарегистрироваться
            </div>
          </div>
        )}
      </div>
      <img src={tels} className="telA" alt="" />
    </div>
  );
}
